@use 'src/scss/variables.scss' as *;

@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni&family=Noto+Serif+TC&family=Noto+Sans+TC:wght@400;700&family=Tapestry&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&family=Poppins:wght@400;500;600&display=swap');
// font-family: 'Libre Bodoni', serif;
// font-family: 'Noto Serif TC', serif;
// font-family: 'Noto Sans TC', sans-serif;
// font-family: 'Tapestry', cursive;
// font-family: "Poppins", sans-serif;

html {
    overflow-y : auto;
    overflow-x : hidden;

    &::-webkit-scrollbar {
        width: $scroll-bar-w;
        height: auto;
        background-color: rgb(44, 63, 108);
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgb(90, 155, 173);
        border-radius: 4px;
    }
}

body {
    min-height: 100vh;
    font-family: Georgia, 'Times New Roman', 'Noto Serif TC', serif;
}

.App {
    min-height: 100vh;
}

.main_app {
    min-height: 100vh;
}

// ? Media Query
@media (width >= 1400px) {
    .main_app {
        margin-left: 60px;
    }
}

@media (width < 1400px) {
    html {
        ::-webkit-scrollbar {
            width: 0;
        }
    }

    .main_app {
        margin-top: 60px;
    }
}

// ? 共用樣式

.sidebar {
    a {
        text-decoration: none;
        color: white;
    }
}

i {
    z-index: 0;
}

b {
    font-weight: bold;
}

.hl {
    &-y {
        background: rgba(252, 255, 153, 0.5);
        border-radius: 4px;
    }
    &-g {
        background: rgba(180, 255, 153, 0.5);
        border-radius: 4px;
    }
    &-p {
        background: rgba(216, 153, 255, 0.5);
        border-radius: 4px;
    }
}