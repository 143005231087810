@use 'variables.scss' as *;

@mixin outer-container() {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: $background-color fixed;
}

@mixin inner-container($x:30px, $y:40px) {
    padding: $x $y;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
}