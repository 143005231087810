$indentation: 70px;

@media (width >= 1400px) {
    .sidebar2__divider {
        display: none;
    
        &.sidebar_active {
            display: block;
            height: 1px;
            width: 379px - $indentation;
            border-radius: 1px;
            background-color: rgb(48, 121, 124);
            transition: all 0.5s;
            margin: 1px 8px 1px 8px + $indentation; // !
        }
    
        &.sidebar_opt_hide {
            margin: 0px 8px 0px 8px + $indentation; // !
            height: 0;
            width: 0;
        }
    }
    
    .sidebar2__topic {
        height: 0;
        width: 0;
        display: flex;
        justify-content: left;
        align-items: center;
        margin: 0 0 0 $indentation;
        border-radius: 6px;
        overflow: hidden;
        transition: all 0.4s;
    
        span {
            margin-left: 10px;
            font-family: 'Libre Bodoni', 'Noto Serif TC', serif;
            display: none;
        }
    
        &__option {
            display: none;
        }
    
        &__option.option_use {
            display: block;
            background-color: rgb(33, 85, 137);
            box-shadow: 1px 1px 2px rgb(22, 69, 116);
            border-radius: 6px;
            height: 28px;
            margin-right: 8px;
            transition: all 0.3s;
    
            i.option {
                font-size: 20px;
                color: rgb(255, 255, 255);
                transition: all 0.5s;
                transform: rotate(90deg);
            }
        }
    
        &.sidebar_active {
            display: flex;
            height: 35px;
            width: 379px - $indentation;
            margin: 0 0 0 $indentation + 6px;
    
            span {
                display: inline;
                flex: 1;
                font-size: 16px;
                padding-top: 3px;
                text-overflow: ellipsis;
            }
    
            .sidebar2__topic__option {
                display: none;
            }
    
            .sidebar2__topic__option.option_use {
                flex: 0 0 40px;
                display: flex;
                justify-content: center;
                align-items: center;
    
                &:hover {
                    background-color: rgb(72, 118, 163);
                    box-shadow: 1px 1px 2px rgb(39, 105, 171);
                }
    
                i.option.option_open {
                    transform: rotate(0deg);
                }
            }
        }
    
        &.sidebar_opt_hide {
            height: 0;
            width: 0;
        }
    
        &:hover {
            background-color: rgb(40, 55, 87);
            color:rgb(138, 199, 245);
        }
    }
}

@media (width < 1400px) {
    .sidebar2__divider {
        display: none;
    
        &.mobile_sidebar_active {
            display: block;
            height: 1px;
            width: 379px - $indentation;
            border-radius: 1px;
            background-color: rgb(48, 121, 124);
            transition: all 0.5s;
            margin: 1px 8px 1px 8px + $indentation; // !
        }
    
        &.sidebar_opt_hide {
            margin: 0px 8px 0px 8px + $indentation; // !
            height: 0;
            width: 0;
        }
    }
    
    .sidebar2__topic {
        height: 0;
        width: 0;
        display: flex;
        justify-content: left;
        align-items: center;
        margin: 0 0 0 $indentation;
        border-radius: 6px;
        overflow: hidden;
        transition: all 0.4s;
    
        span {
            margin-left: 10px;
            font-family: 'Libre Bodoni', 'Noto Serif TC', serif;
            display: none;
        }
    
        &__option {
            display: none;
        }
    
        &__option.option_use {
            display: block;
            background-color: rgb(33, 85, 137);
            box-shadow: 1px 1px 2px rgb(22, 69, 116);
            border-radius: 6px;
            height: 28px;
            margin-right: 8px;
            transition: all 0.3s;
    
            i.option {
                font-size: 20px;
                color: rgb(255, 255, 255);
                transition: all 0.5s;
                transform: rotate(90deg);
            }
        }
    
        &.mobile_sidebar_active {
            display: flex;
            height: 35px;
            width: 379px - $indentation;
            margin: 0 0 0 $indentation + 6px;
    
            span {
                display: inline;
                flex: 1;
                font-size: 16px;
                padding-top: 3px;
                text-overflow: ellipsis;
            }
    
            .sidebar2__topic__option {
                display: none;
            }
    
            .sidebar2__topic__option.option_use {
                flex: 0 0 40px;
                display: flex;
                justify-content: center;
                align-items: center;
    
                &:hover {
                    background-color: rgb(72, 118, 163);
                    box-shadow: 1px 1px 2px rgb(39, 105, 171);
                }
    
                i.option.option_open {
                    transform: rotate(0deg);
                }
            }
        }
    
        &.sidebar_opt_hide {
            height: 0;
            width: 0;
        }
    
        &:hover {
            background-color: rgb(40, 55, 87);
            color:rgb(138, 199, 245);
        }
    }
}