@use 'src/scss/mixins.scss' as *;

.user_container {
    @include outer-container();
}

.user {
    @include inner-container();
    width: calc(100% - 60px);
    max-width: 1200px;
}