@media (width >= 1400px) {
    .my_icon {
        height: 60px;
        width: 400px;
        overflow: hidden;
    
        &__dynamic {
            height: 64px;
            background-color: rgb(26, 32, 46);
            position: relative;
            display: flex;
            justify-content: left;
            align-items: center;
            transition: all 0.8s;
            margin: 3px 0 0;
    
            &__wrap {
                flex: 0 0 60px;
                display: flex;
                justify-content: center;
                align-items: center;
    
                img {
                    width: 40px;
                    border-radius: 3px;
                }
    
            }
            p {
                display: none;
            }
            
        }
    
        &.sidebar_active {
            .my_icon__dynamic {
                height: 52px;
                width: 379px;
                margin: 8px 6px 5px;
                background-color: rgb(39, 83, 105);
                box-shadow: 2px 2px 4px rgb(37, 69, 105);
                border-radius: 6px;
    
                &__wrap {
                    flex: 0 0 52px;
                }
        
                p {
                    display: inline;
                    font-family: 'Tapestry', cursive;
                    margin-left: 4px;
                    font-size: 24px;
                }
            }
        }
    }
}

@media (width < 1400px) {
    .my_icon {
        height: 60px;
        width: 500px;
        overflow: hidden;
    
        &__dynamic {
            height: 60px;
            position: relative;
            display: flex;
            justify-content: left;
            align-items: center;
            transition: all 0.8s;
    
            &__wrap {
                flex: 0 0 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 5px;
    
                img {
                    width: 40px;
                    border-radius: 3px;
                }
    
            }
            p {
                font-family: 'Tapestry', cursive;
                font-size: 28px;
            }
            
        }
    }


    @media (width < 650px) {
        .my_icon {
            width: 350px;

            .my_icon__dynamic {
                p {
                    font-size: 20px;
                }
            }
        }
    }
}