$indentation: 30px;
$icon_width: 50px;

@media (width >= 1400px) {
    .sidebar1__divider {
        display: none;
    
        &.sidebar_active {
            display: block;
            height: 1px;
            width: 379px - $indentation;
            border-radius: 1px;
            background-color: rgb(48, 121, 124);
            transition: all 0.5s;
            margin: 1px 8px 1px 8px + $indentation; // !
        }
    }
    
    .sidebar1__topic {
        height: 0;
        width: 0;
        display: flex;
        justify-content: left;
        align-items: center;
        margin: 0 0 0 $indentation;
        border-radius: 6px;
        overflow: hidden;
        transition: all 0.4s;
    
        &__icon {
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 0 0px;
    
            i {
                color: rgb(174, 215, 255);
                font-size: 20px;
            }
            svg {
                color: rgb(174, 215, 255);
                filter: invert(79%) sepia(26%) saturate(2253%) hue-rotate(185deg) brightness(113%) contrast(101%);
                height: 20px;
                width: 20px;
            }
        }
        span {
            font-family: 'Libre Bodoni', 'Noto Serif TC', serif;
            display: none;
        }
    
        &__star {
            display: block;
            background-color: rgb(33, 85, 137);
            box-shadow: 2px 2px 4px rgb(22, 69, 116);
            border-radius: 6px;
            height: 24px;
            margin-right: 8px;
    
            i.star {
                font-size: 16px;
                color: rgb(234, 222, 119)
            }
        }
    
        &__star.star_not_use {
            display: none;
        }
    
        &__option {
            display: none;
        }
    
        &__option.option_use {
            display: block;
            background-color: rgb(33, 85, 137);
            box-shadow: 1px 1px 2px rgb(22, 69, 116);
            border-radius: 6px;
            height: 28px;
            margin-right: 8px;
            transition: all 0.3s;
    
            i.option {
                font-size: 20px;
                color: rgb(255, 255, 255);
                transition: all 0.5s;
                transform: rotate(90deg);
            }
        }
    
        &.sidebar_active {
            display: flex;
            height: 40px;
            width: 379px - $indentation;
            margin: 0 0 0 $indentation + 6px;
    
            .sidebar1__topic__icon {
                flex: 0 0 $icon_width;
            }
    
            span {
                display: inline;
                flex: 1;
                font-size: 18px;
                padding-top: 3px;
                text-overflow: ellipsis;
            }
    
            .sidebar1__topic__star {
                flex: 0 0 60px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
    
            .sidebar1__topic__star.star_not_use {
                display: none;
            }
    
            .sidebar1__topic__option {
                display: none;
            }
    
            .sidebar1__topic__option.option_use {
                flex: 0 0 40px;
                display: flex;
                justify-content: center;
                align-items: center;
    
                &:hover {
                    background-color: rgb(72, 118, 163);
                    box-shadow: 1px 1px 2px rgb(39, 105, 171);
                }
    
                i.option.option_open {
                    transform: rotate(0deg);
                }
            }
        }
    
        &:hover {
            background-color: rgb(40, 55, 87);
            color:rgb(138, 199, 245);
        }
    }
}

@media (width < 1400px) {
    .sidebar1__divider {
        display: none;
    
        &.mobile_sidebar_active {
            display: block;
            height: 1px;
            width: 379px - $indentation;
            border-radius: 1px;
            background-color: rgb(48, 121, 124);
            transition: all 0.5s;
            margin: 1px 8px 1px 8px + $indentation; // !
        }
    }
    
    .sidebar1__topic {
        height: 0;
        width: 0;
        display: flex;
        justify-content: left;
        align-items: center;
        margin: 0 0 0 $indentation;
        border-radius: 6px;
        overflow: hidden;
        transition: all 0.4s;
    
        &__icon {
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 0 0px;
    
            i {
                color: rgb(174, 215, 255);
                font-size: 20px;
            }
            svg {
                color: rgb(174, 215, 255);
                filter: invert(79%) sepia(26%) saturate(2253%) hue-rotate(185deg) brightness(113%) contrast(101%);
                height: 20px;
                width: 20px;
            }
        }
        span {
            font-family: 'Libre Bodoni', 'Noto Serif TC', serif;
            display: none;
        }
    
        &__star {
            display: block;
            background-color: rgb(33, 85, 137);
            box-shadow: 2px 2px 4px rgb(22, 69, 116);
            border-radius: 6px;
            height: 24px;
            margin-right: 8px;
    
            i.star {
                font-size: 16px;
                color: rgb(234, 222, 119)
            }
        }
    
        &__star.star_not_use {
            display: none;
        }
    
        &__option {
            display: none;
        }
    
        &__option.option_use {
            display: block;
            background-color: rgb(33, 85, 137);
            box-shadow: 1px 1px 2px rgb(22, 69, 116);
            border-radius: 6px;
            height: 28px;
            margin-right: 8px;
            transition: all 0.3s;
    
            i.option {
                font-size: 20px;
                color: rgb(255, 255, 255);
                transition: all 0.5s;
                transform: rotate(90deg);
            }
        }
    
        &.mobile_sidebar_active {
            display: flex;
            height: 40px;
            width: 379px - $indentation;
            margin: 0 0 0 $indentation + 6px;
    
            .sidebar1__topic__icon {
                flex: 0 0 $icon_width;
            }
    
            span {
                display: inline;
                flex: 1;
                font-size: 18px;
                padding-top: 3px;
                text-overflow: ellipsis;
            }
    
            .sidebar1__topic__star {
                flex: 0 0 60px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
    
            .sidebar1__topic__star.star_not_use {
                display: none;
            }
    
            .sidebar1__topic__option {
                display: none;
            }
    
            .sidebar1__topic__option.option_use {
                flex: 0 0 40px;
                display: flex;
                justify-content: center;
                align-items: center;
    
                &:hover {
                    background-color: rgb(72, 118, 163);
                    box-shadow: 1px 1px 2px rgb(39, 105, 171);
                }
    
                i.option.option_open {
                    transform: rotate(0deg);
                }
            }
        }
    
        &:hover {
            background-color: rgb(40, 55, 87);
            color:rgb(138, 199, 245);
        }
    }
}