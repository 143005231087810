@use 'src/scss/mixins.scss' as *;

.page_not_found_container {
    @include outer-container();
    align-items: center;
}

.page_not_found {
    @include inner-container();
    width: 600px;
    max-width: 80%;

    h1 {
        font-size: 36px;
        font-weight: bold;
        text-align: center;
    }
}

@media (width < 800px) {
    .page_not_found {
        h1 {
            font-size: 26px;
        }
    }
}