@media (width >= 1400px) {
    .sidebar__user__container {
        height: 80px;
        width: 60px;
        position: fixed;
        z-index: 11;
        left: 0;
        bottom: 0;
        background: rgb(3, 57, 93);
        overflow: hidden;
        transition: all 0.1s;
        box-sizing: border-box;
        box-shadow: 0 3px 5px rgb(58, 77, 120) inset;
    
        &.sidebar_active {
            width: 400px;
        }
        
        &.sidebar_authed {
            background: rgb(26, 32, 46);
        }
    }
    
    .sidebar__user{
        height: 80px;
        width: 400px;
        overflow: hidden;
        display: flex;
        justify-content: left;
        align-items: center;
    
        &__dyn {
            height: 50px;
            display: flex;
            justify-content: left;
            align-items: center;
            margin: 15px 0 15px;
            border-radius: 6px;
            transition: all 0.3s;
            flex: 60px;
    
            &__icon {
                width: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
    
                i {
                    color: rgb(174, 215, 255);
                    font-size: 24px;
                }
            }
    
            span {
                display: none;
                font-family: 'Libre Bodoni', 'Noto Serif TC', serif;
            }
    
            &:hover {
                background-color: rgb(34, 43, 63);
                color:rgb(161, 213, 254);
            }
        }
    
        &.sidebar_active {
            .sidebar__user__dyn {
                width: 175px;
                margin: 8px 8px;
                background-color: rgb(29, 48, 89);
                border: 2px solid rgb(22, 107, 172);
                box-shadow: 2px 2px 8px rgb(15, 97, 160);
    
                span {
                    color: white;
                    display: inline;
                    font-size: 18px;
                    padding-top: 3px;
                }
            }
        }
    }
}

@media (width < 1400px) {
    .sidebar__user__container {
        display: none;
    }
}