@use '/src/scss/variables.scss' as *;

@media (width >= 1400px) {
    .sidebar {
        z-index: 10;
        position: fixed;
        height: calc(100% - 80px);
        width: 60px;
        padding-bottom: 10px;
        background: rgb(26, 32, 46);
        overflow: hidden;
        transition: all 0.1s;
        box-sizing: border-box;
        margin-bottom: 80px;
        box-shadow: 0 0 5px rgba(26, 32, 46, 0.662);

        color: white;

        &.sidebar_active {
            width: 400px;
            overflow-y: scroll;
            overflow-x: hidden;
        }

        &::-webkit-scrollbar {
            width: 8px;
            height: auto;
            background-color: rgb(44, 63, 108);
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgb(90, 155, 173);
            border-radius: 4px;
        }

        &__upper a.nav_now_on {
            .sidebar__topic.sidebar_active,
            .sidebar1__topic.sidebar_active,
            .sidebar2__topic.sidebar_active {
                background-color: rgb(53, 66, 96);
                color:rgb(161, 237, 254);
            }
        }

        &__logout {
            cursor: pointer;
        }

        &__upper > a {
            pointer-events: none;
        }

        &.sidebar_active &__upper > a {
            pointer-events: all;
        }

        .hamburger {
            display: none;
        }
    }
}

@media (width < 1400px) {
    .sidebar {
        z-index: 10;
        position: fixed;
        top: 0;
        left: 0;
        height: 60px;
        width: 100%;
        padding-bottom: 10px;
        background: rgb(26, 32, 46);
        overflow: hidden;
        transition: all 0.1s;
        box-sizing: border-box;
        box-shadow: 0 0 5px rgba(26, 32, 46, 0.662);

        color: white;

        &::-webkit-scrollbar {
            width: 0px;
            height: auto;
            background-color: rgb(44, 63, 108);
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgb(114, 220, 197);
            border-radius: 4px;
        }

        .mobile_navbar {
            height: 60px;
            width: calc(100% - 60px - $scroll-bar-w);
            margin: 0 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: fixed;
            background: rgb(26, 32, 46);
            z-index: 1;
            
            button{
                height: 40px;
                width: 40px;
                color: white;
                background: transparent;
                border: rgb(170, 192, 255) 1px solid;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
            i {
                display: none;
                font-size: 24px;
            }
            i.hamburger_state {
                display: inline;
            }

            &::after {
                content: none;
                position: absolute;
                left: 20px;
                bottom: 0px;
                width: 100%;
                height: 1px;
                background: rgb(178, 213, 223);
            }
        }

        &__upper {
            display: flex;
            flex-direction: column;
            align-items: center;

            .mobile_navbar_content {
                margin-top: 60px;
            }
        }

        &.mobile_sidebar_active {
            height: 100%;
            background: linear-gradient(180deg, rgb(26, 32, 46), rgb(50, 68, 110));
            overflow-y: scroll;
            overflow-x: hidden;

            .mobile_navbar::after {
                content: '';
            }
        }

        // ! left unchecked
        &__upper > a.nav_now_on {
            .sidebar__topic.sidebar_active,
            .sidebar1__topic.sidebar_active,
            .sidebar2__topic.sidebar_active {
                background-color: rgb(53, 66, 96);
                color:rgb(161, 237, 254);
            }
        }

        &__logout {
            cursor: pointer;
        }

        &__upper > a {
            pointer-events: none;
        }

        &.sidebar_active &__upper > a {
            pointer-events: all;
        }
    }

    @media (width < 650px) {
        .sidebar {
            .mobile_navbar {
                margin: 0 10px;
            }
        }
    }
}