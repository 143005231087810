@use 'src/scss/mixins.scss' as *;

$title-p-b: 20px; 

.home_container {
    @include outer-container();
}

.home {
    max-width: 1200px;
    min-height: 100%;
    margin: 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &__title {
        position: relative;
        max-width: 1048px;
        padding: $title-p-b 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border-radius: 10px;
        background: rgba(167, 212, 255, 0.9);
        box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
        transform: skew(-10deg);

        &_txt {
            position: relative;
            z-index: 1;
            font-family: 'Tapestry', cursive;
            font-size: 72px;
            transform: skew(10deg);
        }
        .color {
            position: absolute;
            height: 28px;
            max-width: 930px;
            left: 3px;
            bottom: 6px;
            margin: 0;
            border-radius: 2px;
            z-index: -1;
            background: #f0de69dd;
            transform: skewX(-30deg);
        }
    }

    &__content {
        h2 {
            font-size: 32px;
            color: rgb(17, 30, 119);
            font-weight: bold;
        }
        p {
            margin: 10px 0 0;
            font-size: 20px;
            line-height: 1.4;
            text-indent: 30px;
        }
        ul {
            margin: 5px 0 0;
        }
        li {
            margin: 5px 0 0;
            font-size: 20px;
            line-height: 1.2;
        }
        .short {
            font-size: 14px;
            background: rgba(147, 147, 139, 0.5);
            border-radius: 4px;    
        }
        a {
            color: rgb(45, 35, 187);
            font-weight: bold;
        }

        &_intro {
            @include inner-container();
            margin-top: 20px;
        }

        &_tech {
            @include inner-container();
            margin-top: 20px;
        }
        
        &_service {
            @include inner-container();
            margin-top: 20px;
        }
    }

    &__social {
        margin-top: 30px;
        width: 450px;
        display: flex;
        justify-content: space-between;

        .h-icon {
            @include inner-container();
            width: 120px;
            height: 120px;
            display: flex;
            justify-content: center;
            align-items: center;

            a {
                color: #000;
            }
            i {
                font-size: 60px;
            }
        }
    }
}