@use 'src/scss/mixins.scss' as *;

.register_container {
    @include outer-container();
    align-items: center;
  }

.register {
  @include inner-container();
  width: 450px;
  font-size: 20px;
  font-weight: bold;
  font-family: "Poppins", "Noto Sans TC";

  p.err_msg {
    display: block;
    background-color: rgba(198, 0, 0, 0.811);
    padding: 10px;
    margin: 0 0 20px;
    font-size: 18px;
    font-weight: normal;
  }
  
  p.offscreen {
    display: none;
  }

  h1 {
    display: block;
    margin: 10px 0 50px;
    font-size: 32px;
    text-align: center;
  }

  .txt_field {
    position: relative;
    border-bottom: 2px solid #666666;
    margin: 30px 0;

    input {
      width: 100%;
      padding: 5px 5px 0;
      box-sizing: border-box;
      height: 35px;
      font-size: 18px;
      font-family: inherit;
      border: none;
      background: none;
      outline: none;

      &#userId::placeholder {
        font-size: 18px;
        color: rgb(93, 93, 93);
        transform: translateY(3px);
      }
    }

    label {
      position: absolute;
      top: 50%;
      left: 5px;
      color: #666666;
      transform: translateY(-50%);
      font-size: 16px;
      pointer-events: none;
      transition: 0.5s;

      span {
        margin-left: 5px;
        &.valid {
          display: inline;
          color: rgb(43, 149, 31)
        }
        &.invalid {
          display: inline;
          color: rgb(190, 0, 0);
        }
        &.hide {
          display: none;
        }
      }
    }

    > span::before {
      content: "";
      position: absolute;
      top: 35px;
      left: 0;
      width: 0%;
      height: 2px;
      background: #006fb9;
      transition: 0.5s;
    }

    input:focus ~ label,
    input:valid ~ label {
      top: -3px;
      color: #006fb9;
    }

    input:focus ~ span::before,
    input:valid ~ span::before {
        width: 100%;
    }
  }

  button {
    display: block;
    width: 100%;
    height: 50px;
    margin: 30px 0 0;
    border: 1px solid;
    background: #006fb9d2;
    border-radius: 25px;
    font-size: 18px;
    color: #e9f4fb;
    font-weight: 700;
    outline: none;

    &:hover{
      border-color: #006fb9;
      transition: .5s;
    }

    &:disabled {
      background: rgb(156, 156, 156);
      border: none;
    }
  }

  &_goLogin {
    display: block;
    margin: 20px 0 0;
    padding: 5px;
    font-weight: normal;
    font-size: 16px;

    a {
      color: #006fb9;
      font-weight: border;
    }
  }

  .instructions {
    display: flex;
    margin: -27px 0 -10px;
    padding: 8px;
    background-color: #006fb991;
    border-radius: 5px;
    font-size: 15px;
    font-weight: normal;

    .info_icon {
      margin-right: 4px;
    }
  }

  .offscreen {
    display: none;
  }
}
