@media (width >= 1400px) {
    .sidebar__divider {
        display: block;
        border-radius: 1px;
        height: 2px;
        width: 50px;
        margin: 3px 5px;
        background-color: cadetblue;
        transition: all 0.5s;
    
        &.sidebar_active {
            width: 379px;
            margin: 3px 8px;
        }
    }
    
    .sidebar__topic {
        height: 50px;
        display: flex;
        justify-content: left;
        align-items: center;
        margin: 0;
        border-radius: 6px;
        width: 379px;
        overflow: hidden;
        transition: all 0.8s;
    
        &__icon {
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 0 60px;
    
            i {
                color: rgb(174, 215, 255);
                font-size: 24px;
            }
        }
    
        span {
            display: none;
            font-family: 'Libre Bodoni', 'Noto Serif TC', serif;
        }
    
        &.sidebar_active {
            margin: 0 0 0 6px;
    
            span {
                display: inline;
                font-size: 20px;
                padding-top: 6px;
            }
        }
    
        &:hover {
            background-color: rgb(40, 55, 87);
            color:rgb(138, 199, 245);
        }
    }
}

@media (width < 1400px) {
    .sidebar__divider {
        display: block;
        border-radius: 1px;
        height: 2px;
        width: 50px;
        margin: 3px 5px;
        background-color: cadetblue;
        transition: all 0.5s;
    
        &.mobile_sidebar_active {
            width: 379px;
            margin: 3px 8px;
        }
    }
    
    .sidebar__topic {
        height: 50px;
        display: flex;
        justify-content: left;
        align-items: center;
        margin: 0;
        border-radius: 6px;
        width: 379px;
        overflow: hidden;
        transition: all 0.8s;
    
        &__icon {
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 0 60px;
    
            i {
                color: rgb(174, 215, 255);
                font-size: 24px;
            }
        }
    
        span {
            display: none;
            font-family: 'Libre Bodoni', 'Noto Serif TC', serif;
        }
    
        &.mobile_sidebar_active {
            margin: 0 0 0 6px;
    
            span {
                display: inline;
                font-size: 20px;
                padding-top: 6px;
            }
        }
    
        &:hover {
            background-color: rgb(40, 55, 87);
            color:rgb(138, 199, 245);
        }
    }
}